<template>
  <!-- <img alt="Vue logo" src="assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="home">
    <main
      id="MainContent"
      class="content-for-layout focus-none"
      role="main"
      tabindex="-1"
    >
      <div
        id="shopify-section-template--15211785846828__16528913039db81bb0"
        class="shopify-section"
      >
        <!-- Banner -->
        <section class="main-banner col-12">
          <div class="green-banner"></div>
          <div class="light-green"></div>
          <div class="banner-container container">
            <div class="banner-slider">
              <div class="banner-slides">
                <div class="row align-item">
                  <div
                    class="banner-text wow bounceInLeft"
                    data-wow-duration="2s"
                  >
                    <h1>
                      Want to build a <span class="blue">Website</span> or
                      <span class="green">Mobile Application?</span>
                    </h1>

                    <button
                      name="add"
                      class="btn banner-btn custom-button animation-button pop-btn"
                    >
                      <span>Get Free Consultation</span>
                    </button>
                  </div>

                  <div
                    class="banner-img wow bounceInRight"
                    data-wow-duration="2s"
                  >
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/2b_45fc7.png?v=1655733334"
                      alt="banner image"
                      class="img-holder"
                      defer
                    />
                  </div>
                </div>
              </div>
              <div class="banner-slides">
                <div class="row align-item">
                  <div
                    class="banner-text wow bounceInLeft"
                    data-wow-duration="2s"
                  >
                    <h1>
                      Want to Build an
                      <span class="blue">E-commerce</span> Store?
                    </h1>

                    <button
                      name="add"
                      class="btn banner-btn custom-button animation-button pop-btn"
                    >
                      <span>Get Free Consultation</span>
                    </button>
                  </div>

                  <div
                    class="banner-img wow bounceInRight"
                    data-wow-duration="2s"
                  >
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Frame_3515fc7.png?v=1655733334"
                      alt="banner image"
                      class="img-holder"
                      defer
                    />
                  </div>
                </div>
              </div>
              <div class="banner-slides">
                <div class="row align-item">
                  <div
                    class="banner-text wow bounceInLeft"
                    data-wow-duration="2s"
                  >
                    <h1>
                      Get a Custom <span class="green">Software</span> for
                      <span class="blue">Your Business</span>
                    </h1>

                    <button
                      name="add"
                      class="btn banner-btn custom-button animation-button pop-btn"
                    >
                      <span>Get Free Consultation</span>
                    </button>
                  </div>

                  <div
                    class="banner-img wow bounceInRight"
                    data-wow-duration="2s"
                  >
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Frame_3595fc7.png?v=1655733334"
                      alt="banner image"
                      class="img-holder"
                      defer
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="banner-slides">
                <div class="row align-item">
                  <div
                    class="banner-text wow bounceInLeft"
                    data-wow-duration="2s"
                  >
                    <h1>
                      <span class="green"> Cost-effective </span> Alternatives
                      to Traditional <span class="blue">Hiring</span>
                    </h1>

                    <button
                      name="add"
                      class="btn banner-btn custom-button animation-button pop-btn"
                    >
                      <span>Get Free Consultation</span>
                    </button>
                  </div>

                  <div
                    class="banner-img wow bounceInRight"
                    data-wow-duration="2s"
                  >
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Frame_3589203.png?v=1655733335"
                      alt="banner image"
                      class="img-holder"
                      defer
                    />
                  </div>
                </div>
              </div>
              <div class="banner-slides">
                <div class="row align-item">
                  <div
                    class="banner-text wow bounceInLeft"
                    data-wow-duration="2s"
                  >
                    <h1>
                      Hire Remote <span class="blue">Individuals</span> or
                      <span class="green">Teams </span>
                    </h1>

                    <button
                      name="add"
                      class="btn banner-btn custom-button animation-button pop-btn"
                    >
                      <span>Get Free Consultation</span>
                    </button>
                  </div>

                  <div
                    class="banner-img wow bounceInRight"
                    data-wow-duration="2s"
                  >
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Group_424_5d3b4cbe-3a8a-4a46-8735-d2af3c8e70be6905.png?v=1655739305"
                      alt="banner image"
                      class="img-holder"
                      defer
                    />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </section>
        <!-- End Banner -->
      </div>
      <div
        id="shopify-section-template--15211785846828__16529803700c068c78"
        class="shopify-section"
      >
        <!-- Image with Text -->
        <section class="image-with-text">
          <div class="container img-with-txt-backgroung">
            <div class="h2-heading wow fadeInUp">
              <h2>Our Process</h2>
            </div>

            <div class="row overflow-hide our-process">
              <div class="col-md-6 col-sm-12 img-text-image">
                <img
                  class="wow zoomIn"
                  data-wow-delay="0.3s"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Group_33622e.png?v=1654194106"
                  alt="image"
                  defer
                />
              </div>

              <div
                class="col-md-6 col-sm-12 img-text wow rollIn"
                data-wow-delay="0.3s"
              >
                <ul class="list-ic vertical">
                  <li>
                    <span>1</span>

                    <p>Book a Free Consultation</p>
                  </li>
                  <li>
                    <span>2</span>

                    <p>Highlight your web and mobile development needs</p>
                  </li>
                  <li>
                    <span>3</span>

                    <p>Finalize a price</p>
                  </li>
                  <li>
                    <span>4</span>

                    <p>Product design</p>
                  </li>
                  <li>
                    <span>5</span>

                    <p>Get it developed and go live</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="img-text-btn wow fadeInDown">
              <button
                name="add"
                class="btn custom-button animation-button pop-btn"
              >
                <span>Let’s Talk</span>
              </button>
            </div>
          </div>
        </section>
        <!-- End Image with Text -->
      </div>
      <div
        id="shopify-section-template--15211785846828__1653048930d534e864"
        class="shopify-section"
      >
        <!-- Technologies Section -->
        <section class="technologies-sec">
          <div class="container">
            <div class="h2-heading wow zoomIn" data-wow-delay="0.4s">
              <h2>Technologies We Work With</h2>
            </div>

            <div
              class="row tech-sec wow zoomIn align-tech"
              data-wow-delay="0.4s"
            >
              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Vue_Js503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Kubernetes503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="images/flutter-orignal.svg"
                  style="filter: grayscale(1)"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Shopify503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Ios503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/PHP503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Rails503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/React_Native503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/React503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Node_Js503c.png?v=1654194183"
                  defer
                />
              </div>

              <div class="tech-logo">
                <img
                  class="img-holder"
                  src="cdn.shopify.com/s/files/1/0578/3132/5740/files/ezgif.com-gif-maker18c7.png?v=1654194379"
                  defer
                />
              </div>
            </div>
          </div>
        </section>
        <!-- End Technologies section -->
      </div>
      <div
        id="shopify-section-template--15211785846828__1653051023a148c1a3"
        class="shopify-section"
      >
        <!-- Projects Slider -->
        <section class="project-slider">
          <div class="container">
            <div class="h2-heading wow zoomIn" data-wow-delay="0.4s">
              <h2>Work Which Speaks for Itself</h2>
            </div>

            <div class="project-sec-slider">
              <div class="project-container">
                <div class="row wow zoomIn">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/FlavorwikiMockup24cf7.png?v=1654192480"
                      defer
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 project-dec">
                    <h3>FlavorWiki</h3>
                    <div class="project-descrption desktop-view">
                      <p>
                        <span class="bold">FlavorWiki®</span> Share your tasting
                        experience and earn great rewards:<br /><br />
                        1. Provides insights to famous brands in the food
                        industry.<br />
                        2. Become a taster to earn rewards.<br />
                        3. Participate in surveys and share your experience with
                        friends.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/PHP_480x48017ce.png?v=1652450468"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720_160x160.webp?v=1652450258"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://flavorwiki.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="project-container">
                <div class="row wow zoomIn">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/ZiplyMockup6df2.png?v=1654192516"
                      defer
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 project-dec">
                    <h3>Ziply</h3>
                    <div class="project-descrption desktop-view">
                      <p>
                        <span class="bold">Ziply®</span> Ziply operates as an
                        on-demand shipping service, allowing customers to order
                        couriers to deliver anything in an efficient manner
                        without having to leave their homes:<br /><br />
                        1. Same day, door-to-door delivery.<br />
                        2. Couriers are available 24/7, 365 days a year.<br />
                        3. Request from your phone, laptop or desktop.<br />
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Iosec90.png?v=1652450426"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://goziply.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="project-container">
                <div class="row wow zoomIn">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/NYAdventureClubMockup1dd1.png?v=1654192497"
                      defer
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 project-dec">
                    <h3>New York Adventure Club</h3>
                    <div class="project-descrption desktop-view">
                      <p>
                        <span class="bold">New York Adventure Club®</span>
                        Enabling the discovery of hidden gems in NYC and beyond
                        through physical exploration:<br /><br />
                        1. Explore the unknown stories of everyday places.<br />
                        2. Learn from experts who define urban culture.<br />
                        3. Join us physically or virtually.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720.webp?v=1652450258"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a
                          href="https://www.nyadventureclub.com/"
                          target="_blank"
                        >
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="project-container">
                <div class="row wow zoomIn">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/PlexxisMockup35d4.png?v=1654192501"
                      defer
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 project-dec">
                    <h3>Plexxis</h3>
                    <div class="project-descrption desktop-view">
                      <p>
                        <span class="bold">Plexxis Software®</span> Coupling
                        cloud construction management software, on-premise and
                        hosted solutions, Plexxis unites operations, estimating,
                        accounting and field apps on a single technology
                        stack:<br /><br />
                        1. For subcontractors who seek elite team cohesion and
                        performance.<br />
                        2. Single, unified platform created in-house.<br />
                        3. End-to-end optimization of the construction sector.
                      </p>
                      <div class="row">
                        <div class="mini-logo" style="text-align: start">
                          <img
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720_160x160.webp?v=1652450258"
                            class="img-holder"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://plexxis.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="project-container">
                <div class="row wow zoomIn">
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/LinesheetMockupad97.png?v=1654192485"
                      defer
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 project-dec">
                    <h3>Linesheets</h3>
                    <div class="project-descrption desktop-view">
                      <p>
                        <span class="bold">Linesheets®</span> Streamlined,
                        template-based package that allows you to organize,
                        customize and share your product collections at
                        lightning speed:<br /><br />
                        1. Linesheet automation.<br />
                        2. Facilitates the targeting of small boutiques and
                        large retailers.<br />
                        3. A great alternative to hiring professional designers.
                      </p>
                      <div class="row">
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720_160x160.webp?v=1652450258"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWSa455.png?v=1652450413"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://www.linesheets.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="img-text-btn wow fadeInUp">
              <a class="btn custom-button animation-button" href="/portfolio">
                <span>Check Out More Projects</span>
              </a>
            </div>
          </div>
        </section>
        <!-- End Projects Slider -->
      </div>
      <div
        id="shopify-section-template--15211785846828__16530573415ab617aa"
        class="shopify-section"
      >
        <!-- Engagement Model -->
        <section class="engagement-sec">
          <div class="container">
            <div class="h2-heading wow zoomIn" data-wow-delay="0.4s">
              <h2>Choose Your Engagement Model</h2>
            </div>

            <div class="row">
              <div
                class="engagement-model wow zoomInRight"
                data-wow-duration="2s"
              >
                <div class="engagement-des">
                  <h4 class="heading">Fixed Cost Projects</h4>
                  <p>
                    Work with a team of developers, project managers,
                    consultants and marketers, tailored to fit your budget and
                    needs.
                  </p>
                  <ul class="engagement-list">
                    <li>Discuss <span class="bold">the scope</span></li>
                    <li>Get an <span class="bold">estimate</span></li>
                    <li>Begin <span class="bold">the development</span></li>
                  </ul>
                </div>
              </div>

              <div
                class="engagement-model wow popular bounceInUp"
                data-wow-duration="2s"
              >
                <div class="engagement-des">
                  <div class="popular-position">
                    <img
                      src="cdn.shopify.com/s/files/1/0641/0924/8766/files/popular3df7.png?v=1653056722"
                      alt="logo"
                      defer
                    />
                    <span>Popular</span>
                  </div>

                  <h4 class="heading">Hire a Dedicated Team</h4>
                  <p>
                    Employ the best developers, designers, project managers and
                    QA specialists at highly competitive rates.
                  </p>
                  <ul class="engagement-list">
                    <li>
                      Outline personnel <span class="bold"> requirements </span>
                    </li>
                    <li>
                      Get the best mix of
                      <span class="bold"> professionals </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="engagement-model wow zoomInRight"
                data-wow-duration="2s"
              >
                <div class="engagement-des">
                  <h4 class="heading">Hire a Dedicated Developer</h4>
                  <p>
                    Top-of-the-line talent available at highly affordable
                    prices, available to work on your schedule.
                  </p>
                  <ul class="engagement-list">
                    <li>
                      Discuss the <span class="bold">ideal candidate</span>
                    </li>
                    <li>Test their <span class="bold">competency</span></li>
                    <li>Hire them for <span class="bold">remote work</span></li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="img-text-btn pop-btn wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <a class="btn custom-button animation-button">
                <span>Book a Free Consultation</span>
              </a>
            </div>
          </div>
        </section>
        <!-- End Technologies Section -->
      </div>
      <div
        id="shopify-section-template--15211785846828__165306200492e88c10"
        class="shopify-section"
      >
        <section class="team-info-sec">
          <div class="container">
            <h2 class="sec-team-heading wow fadeInUp">
              Don’t Just Take Our Word For It
            </h2>

            <div class="testimonials">
              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Asaafee54.png?v=1654679680"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="testimonial-img col-md-5 col-sm-12 mobile-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Untitled-1cbd4.jpg?v=1654193338"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">Assaf Eshel</h3>
                        <p class="font-title">
                          Vice President, Ecommerce, Sonovia
                        </p>
                      </div>
                      <div class="description-des">
                        <p>
                          I have been working with Centena for over two years.
                          During this time they helped me build five websites
                          and maintain them on a regular basis including fixing
                          bugs, implementing new features, complicated
                          functionality and more.They have comprehensive
                          knowledge in many areas, which is expected from such
                          company, but the quality of service is above any
                          expectation.In the world of e-commerce response times
                          are critical, and there they have never disappointed
                          but have continued to surprise time and time again.To
                          me they are a partner in the journey and not a service
                          provider.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/George_9de51ffe-76d4-48b8-a62c-3df2e185f326374c.png?v=1654679734"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="testimonial-img col-md-5 col-sm-12 mobile-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Georgef8e9.png?v=1654193412"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">George Hofstetter</h3>
                        <p class="font-title">Founder & CEO, GH Technologies</p>
                      </div>
                      <div class="description-des">
                        <p>
                          Centena engineers are professional and timely! They
                          will work hard with your team to ensure quality work
                          is created and maintained.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Daniel374c.png?v=1654679734"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="testimonial-img col-md-5 col-sm-12 mobile-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Daniel_Protz_M6f4f.png?v=1654193359"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">Daniel Protz</h3>
                        <p class="font-title">CEO, FlavorWiki</p>
                      </div>
                      <div class="description-des">
                        <p>
                          Centena has been an amazing addition and critical
                          input to the development of our business at
                          FlavorWiki. They are flexible team players who work to
                          create a team, environment, and structure that works
                          together with our business. We searched for a long
                          time for a development partner and found many were
                          rigid and added services designed to increase the
                          cost. With Centena their teams are able to become part
                          of the company and evolve capabilities and procedures
                          to support the unique needs of our business.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Corey4791.png?v=1654679735"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="testimonial-img col-md-5 col-sm-12 mobile-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/NY_Adventure_M6f4f.png?v=1654193359"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">
                          Corey William Schneider
                        </h3>
                        <p class="font-title">CEO, New York Adventure Club</p>
                      </div>
                      <div class="description-des">
                        <p>
                          Centena has been an invaluable web development partner
                          over the past couple of years. Thanks to them, I've
                          been able to improve the performance of my company's
                          website infrastructure while building out new features
                          in a timely (and cost effective) manner. On top of
                          that, they've always gone above and beyond to address
                          critical website errors as quickly as possible — even
                          during off-hours and weekends.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Daniel_Shore374c.png?v=1654679734"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="testimonial-img col-md-5 col-sm-12 mobile-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Daniel_Shoar_M6f4f.jpg?v=1654193359"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">Daniel Shoar</h3>
                        <p class="font-title">CEO, Bukit</p>
                      </div>
                      <div class="description-des">
                        <p>
                          Centena has been a phenomenal partner in developing
                          Bukit’s vision into a reality. The tireless work
                          ethic, teamwork, and excellent code has allowed us to
                          develop our technology to ensure we at the forefront
                          of innovation in the SaaS and e-commerce space. I
                          thank Centena very much for being a great development
                          partner and look forward to innovating and building
                          something with enormous upside potential. For any of
                          those looking for the best development partners,
                          Centena is the destination of choice!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Promatic_3a0ee5eb-a5de-4106-af48-c78f1335bfc7374c.png?v=1654679734"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="testimonial-img col-md-5 col-sm-12 mobile-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Promatic_M6f4f.png?v=1654193359"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">Shumly Kahanov</h3>
                        <p class="font-title">CEO, Promatic Consulting</p>
                      </div>
                      <div class="description-des">
                        <p>
                          Centena has proven itself to be an excellent offshore
                          partner for all our development needs. The quality of
                          their work, the excellence in their communication, and
                          their culture of transparency are all a testament to
                          the kind of professionalism they inherit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Kenneth_Ma_6acd70d2-6e53-4921-a490-cb6d804c19854791.png?v=1654679735"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="testimonial-img col-md-5 col-sm-12 mobile-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Kenneth_Ma6f4f.png?v=1654193359"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">Kenneth Ma</h3>
                        <p class="font-title">
                          Founder, Mott and Bayard Eyewear
                        </p>
                      </div>
                      <div class="description-des">
                        <p>
                          It was a pleasure working with Centena. They are
                          responsive and replied to e-mails immediately. They
                          would fix issues and problems without a question.
                        </p>
                        <p>
                          Very professional and I would recommend Centena to
                          anyone who have development projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="testimonial-img col-md-5 col-sm-12 desktop-view">
                    <img
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/files/Eradj_Khaidarov_371215d6-e135-44e0-83f1-18f8e6d5f52b374c.png?v=1654679734"
                      class="img-holder image-border"
                      defer
                    />
                  </div>

                  <div class="col-md-7 col-sm-12">
                    <div
                      class="col-md-12 wow slideInRight"
                      data-wow-delay="0.3s"
                    >
                      <div class="team-info-box">
                        <h3 class="team-info-heading">Eradj Khaidarov</h3>
                        <p class="font-title">
                          Chief Technology Officer, ICwhatUC Inc.
                        </p>
                      </div>
                      <div class="description-des">
                        <p>
                          I have had the pleasure of working with Centena for
                          the past 3 years. Centena team lead the conversations
                          with confidence and flexibility allowing us to
                          structure the engagement for success. Centena
                          approached the relationship as a true partnership
                          starting with the candidate selection process and
                          being totally transparent with costs. After working
                          with Centena employees on numerous projects, we feel
                          they are part of our team. They are burning the
                          midnight oil with us when necessary and celebrating
                          our wins. The team at Centena projects confidence and
                          outermost dedication to the client. Centena
                          consistently excel in technical deliveries and take
                          leadership in communication. They go above and beyond
                          day-in and day-out.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>

    <ul hidden>
      <li id="a11y-refresh-page-message">
        Choosing a selection results in a full page refresh.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>
