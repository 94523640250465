<template>
  <div id="shopify-section-custom-header" class="shopify-section">
    <header>
      <div class="header-container container" id="cus-menu">
        <div class="custom-rows">
          <div class="logo-image">
            <a href="/"
              ><img
                class="img-holder wow zoomIn"
                src="https://cdn.cloudious.net/file-1686916877978-821968772.png"
                data-wow-delay="0.1s"
                alt="Centena Logo"
            /></a>
            <!-- src="https://cdn.cloudious.net/file-1686916815281-324091713.svg" -->
            <!-- src="https://cdn.cloudious.net/file-1686916668285-765026986.svg"   -->

            <!-- src="cdn.shopify.com/s/files/1/0578/3132/5740/files/ezgif.com-gif-maker_7154c.png?v=1654195199" -->
          </div>

          <nav class="site-nav desktop-view wow zoomIn" data-wow-delay="0.2s">
            <ul class="menu-list">
              <li :class="{ active: this.$route.name == 'home' }">
                <a href="/">Home</a>
              </li>

              <li :class="{ active: this.$route.name == 'portfolio' }">
                <a href="/portfolio">Portfolio</a>
              </li>

              <!-- <li><a href="blogs/news.html">Blogs</a></li> -->

              <li :class="{ active: this.$route.name == 'faqs' }">
                <a href="/faqs">FAQ's</a>
              </li>
            </ul>
          </nav>
          <div
            class="header-btn hamburger mobile-view wow zoomIn"
            data-wow-delay="0.2s"
          >
            <!-- <img
              class="img-holder"
              src="https://cdn.shopify.com/s/files/1/0578/3132/5740/files/charm_menu-hamburger.svg?v=1654262960"
            /> -->

            <img
              class="img-holder"
              width="48"
              height="48"
              src="https://img.icons8.com/sf-regular/48/29cab3/menu.png"
              alt="menu"
            />
          </div>

          <div class="header-btn desktop-view">
            <button name="add" class="btn quote-btn animation-button pop-btn">
              <span>Get A Quote</span>
            </button>
          </div>
        </div>
      </div>
    </header>

    <!-- Menu Popup -->
    <div class="menu-drawer">
      <div class="menu-drawer-box wow">
        <div class="custom-rows menu-drawer-header">
          <div class="header-btn closs-menu mobile-view">
            <img
              width="40"
              height="40"
              class="img-holder"
              src="https://img.icons8.com/sf-black/40/29cab3/multiply.png"
              alt="close"
            />
            <!-- <img
              class="img-holder"
              src="cdn.shopify.com/s/files/1/0641/0924/8766/files/crossf14f.png?v=1653323627"
            /> -->
          </div>
        </div>
        <ul class="menu-list">
          <li :class="{ active: this.$route.name == 'home' }">
            <a href="/">Home</a>
          </li>

          <li :class="{ active: this.$route.name == 'portfolio' }">
            <a href="/portfolio">Portfolio</a>
          </li>

          <!-- <li><a href="blogs/news.html">Blogs</a></li> -->

          <li :class="{ active: this.$route.name == 'faqs' }">
            <a href="/faqs">FAQ's</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Menu Popup -->
    <div
      class="popupWrapper wow fadeInDown"
      :style="{ display: mailSent ? 'block' : 'none' }"
    >
      <div class="cus-newsletter">
        <h3 id="Newsletter-success--custom-footer" tabindex="-1" autofocus>
          <svg
            aria-hidden="true"
            focusable="false"
            role="presentation"
            class="icon icon-success"
            viewBox="0 0 13 13"
          >
            <path
              d="M6.5 12.35C9.73087 12.35 12.35 9.73086 12.35 6.5C12.35 3.26913 9.73087 0.65 6.5 0.65C3.26913 0.65 0.65 3.26913 0.65 6.5C0.65 9.73086 3.26913 12.35 6.5 12.35Z"
              fill="#428445"
              stroke="white"
              stroke-width="0.7"
            />
            <path d="M5.53271 8.66357L9.25213 4.68197" stroke="white" />
            <path d="M4.10645 6.7688L6.13766 8.62553" stroke="white" />
          </svg>
          Thank you for contacting us!
        </h3>
      </div>
    </div>
  </div>

  <div id="shopify-section-gigalabs-popup" class="shopify-section">
    <!-- Contact Popup -->
    <div class="contact-popup-box">
      <div class="contact-popup wow">
        <div class="row">
          <div class="giga-text-contact">
            <h2 class="news-letter-heading">Get In Touch!</h2>
            <p>
              Please provide the details below. Our representative will get in
              touch with you shortly.
            </p>
            <form
              @submit.prevent="formSubmit"
              id="contact_form"
              accept-charset="UTF-8"
              class="newsletter_contact_giga"
            >
              <input type="hidden" name="form_type" value="customer" /><input
                type="hidden"
                name="utf8"
                value="✓"
              />
              <input type="hidden" name="contact[tags]" value="newsletter" />
              <div>
                <label for="ContactForm-first_name">Name*</label>
                <input
                  autocomplete="name"
                  type="text"
                  id="ContactForm-first_name"
                  name="contact[first_name]"
                  v-model="mailObj.name"
                  aria-required="true"
                  placeholder="Enter Full Name"
                  required
                />
                <label for="NewsletterForm--gigalabs-popup">Email*</label>
                <input
                  id="NewsletterForm--gigalabs-popup"
                  type="email"
                  name="contact[email]"
                  v-model="mailObj.email"
                  aria-required="true"
                  autocorrect="off"
                  autocapitalize="off"
                  autocomplete="email"
                  placeholder="Enter Email Address"
                  required
                />
                <button
                  type="submit"
                  class="submit-gig-contact-form"
                  name="commit"
                  id="Subscribe"
                  :disabled="request"
                  aria-label="Subscribe"
                >
                  Submit

                  <img
                    v-if="request"
                    src="images/icons8-spinner.gif"
                    alt="loading..."
                  />
                  <img
                    v-else
                    src="https://cdn.shopify.com/s/files/1/0578/3132/5740/files/Vector.svg?v=1654261225"
                    alt="Send btn pic"
                  />
                </button>
              </div>
            </form>
          </div>
          <div class="popup-contact-image desktop-view">
            <img
              src="cdn.shopify.com/s/files/1/0578/3132/5740/files/ezgif.com-gif-maker_6b06a.png?v=1654195116"
              alt="Contact Us"
            />
          </div>
        </div>
        <div class="cross">
          <span>x</span>
        </div>
      </div>
      <div
        class="popupWrapper wow fadeInDown"
        :style="{ display: mailError ? 'block' : 'none' }"
      >
        <div class="cus-newsletter-err">
          <small
            class="newsletter-form__message form__message"
            id="Newsletter-error--custom-footer"
            ><svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              class="icon icon-error"
              viewBox="0 0 13 13"
            >
              <circle
                cx="6.5"
                cy="6.50049"
                r="5.5"
                stroke="white"
                stroke-width="2"
              />
              <circle
                cx="6.5"
                cy="6.5"
                r="5.5"
                fill="#EB001B"
                stroke="#EB001B"
                stroke-width="0.7"
              />
              <path
                d="M5.87413 3.52832L5.97439 7.57216H7.02713L7.12739 3.52832H5.87413ZM6.50076 9.66091C6.88091 9.66091 7.18169 9.37267 7.18169 9.00504C7.18169 8.63742 6.88091 8.34917 6.50076 8.34917C6.12061 8.34917 5.81982 8.63742 5.81982 9.00504C5.81982 9.37267 6.12061 9.66091 6.50076 9.66091Z"
                fill="white"
              />
              <path
                d="M5.87413 3.17832H5.51535L5.52424 3.537L5.6245 7.58083L5.63296 7.92216H5.97439H7.02713H7.36856L7.37702 7.58083L7.47728 3.537L7.48617 3.17832H7.12739H5.87413ZM6.50076 10.0109C7.06121 10.0109 7.5317 9.57872 7.5317 9.00504C7.5317 8.43137 7.06121 7.99918 6.50076 7.99918C5.94031 7.99918 5.46982 8.43137 5.46982 9.00504C5.46982 9.57872 5.94031 10.0109 6.50076 10.0109Z"
                fill="white"
                stroke="#EB001B"
                stroke-width="0.7"
              />
            </svg>
            <span class="ml-2"> Something went wrong!</span>
          </small>
        </div>
      </div>
    </div>

    <!-- End Popup -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      mailSent: false,
      mailError: false,
      request: false,
      mailObj: {
        name: "",
        email: "",
      },
    };
  },
  methods: {
    formSubmit() {
      // console.log(this.mailObj);
      this.request = true;
      const config = {
        method: "post",
        url: "https://mail.appick.io/cloudious",
        data: {
          emailTo: this.mailObj.email,
          subject: "Centena",
          text: `Hello ${this.mailObj.name} !\nWe have received your request. Our representative will get in touch with you shortly.\nThank you!`,
          html: "",
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res);
          if (res.data.status == "success") {
            // close the popup
            document.getElementsByClassName("cross")[0].click();
            this.mailObj = {
              name: "",
              email: "",
            };
            this.mailSent = true;
            setTimeout(() => {
              this.mailSent = false;
            }, 5000);
            this.request = false;
          } else {
            this.mailError = true;
            this.request = false;
            setTimeout(() => {
              this.mailError = false;
            }, 5000);
          }
        })
        .catch((err) => {
          // console.log(err);
          this.mailError = true;
          this.request = false;
          setTimeout(() => {
            this.mailError = false;
          }, 5000);
        });
    },
  },
};
</script>

<style scoped></style>
