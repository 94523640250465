<template>
  <div class="">
    <main
      id="MainContent"
      class="content-for-layout focus-none"
      role="main"
      tabindex="-1"
    >
      <div
        id="shopify-section-template--15211785945132__main"
        class="shopify-section"
      >
        <section class="banner-team col-12 faq-page">
          <div class="light-green"></div>
          <div class="faq-section portfolio-container container">
            <div class="team-banner-container wow zoomIn">
              <h2 class="team-banner-text-h2">Frequently Asked Questions</h2>
            </div>

            <!-- <div class="row search-box">
    	<input type="text" name="search" placeholder="Search">
    </div> -->

            <!-- Accordion's -->
            <div
              class="accordion wow zoomIn"
              id="accordionEx1"
              role="tablist"
              aria-multiselectable="true"
            >
              <div class="card">
                <div class="card-header" role="tab" id="heading1">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse1"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">What can you help me with?</h5>
                  </a>
                </div>

                <div
                  id="collapse1"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading1"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      We can help you with any kind of web/mobile software
                      development, Shopify solutions and design.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading2">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse2"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">How do I work with you?</h5>
                  </a>
                </div>

                <div
                  id="collapse2"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading2"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      You can choose to hire a team to do a fixed-cost project
                      or you can hire individual resources on part-time and
                      full-time basis.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading3">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse3"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">
                      What technologies do you specialize in?
                    </h5>
                  </a>
                </div>

                <div
                  id="collapse3"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading3"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      E-commerce: Shopify, Shopify Plus, Headless Ecommerce and
                      Spree.
                    </p>
                    <p>
                      Frontend: Angular JS, React JS, React Native, Vue JS,
                      Gatsby, Next JS,<br />Android, iOS and Flutter.
                    </p>
                    <p>
                      Backend: Node JS, Ruby on Rails, PHP, Python, DotNet,
                      Laravel, Nest,<br />Express, CodeIgniter and Serverless.
                    </p>
                    <p>
                      Dev Ops: Heroku, AWS, Google Cloud, Docker,<br />Azure and
                      CI/CD.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading4">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">
                      What do I need to know before contacting you?
                    </h5>
                  </a>
                </div>

                <div
                  id="collapse4"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading4"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      All the features and functionalities that you want to have
                      in your website/app.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading5">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">
                      How much do you charge for software development?
                    </h5>
                  </a>
                </div>

                <div
                  id="collapse5"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading5"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      We charge market-competitive prices which are quoted to
                      the customer during the<br />preliminary call.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading6">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">How much do you charge for design?</h5>
                  </a>
                </div>

                <div
                  id="collapse6"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading6"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      We charge market-competitive prices which are quoted to
                      the customer during the preliminary call.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading7">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">How do you guarantee quality?</h5>
                  </a>
                </div>

                <div
                  id="collapse7"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading7"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      Our work goes through extensive quality assurance, manual
                      and automated, before the product is delivered to the
                      client. We only collect the final payment after the client
                      is fully satisfied with our work.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading8">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse8"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">
                      How can I test your skills before working with you?
                    </h5>
                  </a>
                </div>

                <div
                  id="collapse8"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading8"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      You can put our resources through extensive evaluation,
                      both through interviews and test assignments. You will
                      only pay for their time if you decide to hire them.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading9">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse9"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">Why should I work with you?</h5>
                  </a>
                </div>

                <div
                  id="collapse9"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading9"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      Our prices are competitive and the turn-around time is
                      exceptional; there’s complete transparency,
                      round-the-clock visibility and post-delivery warranty.
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" role="tab" id="heading11">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordionEx1"
                    href="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapseTwo1"
                  >
                    <h5 class="mb-0">
                      Why should I work with an agency rather than a freelancer?
                    </h5>
                  </a>
                </div>

                <div
                  id="collapse11"
                  class="collapse"
                  role="tabpanel"
                  aria-labelledby="heading11"
                  data-parent="#accordionEx1"
                >
                  <div class="card-body">
                    <p>
                      An agency provides you one resource with a team of
                      multiple professionals behind them. Scaling up your
                      project is much easier and efficient with an agency than a
                      freelancer. An agency can fulfill a diverse set of needs
                      from design to<br />development.   
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Accordion's -->
            <div class="find-ans wow zoomIn">
              <h3>Didn't find an answer?</h3>
              <p>
                If you cannot find answer to your question in our FAQ, you can
                always contact us.<br />We will answer you shortly!
              </p>
            </div>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
