<template>
  <div class="">
    <main
      id="MainContent"
      class="content-for-layout focus-none"
      role="main"
      tabindex="-1"
    >
      <div
        id="shopify-section-template--15211785551916__portfolio"
        class="shopify-section"
      >
        <section class="banner-team portfolio-page">
          <div class="light-green"></div>
          <div class="portfolio-container container">
            <div class="team-banner-container">
              <div class="col-12 row align-ite">
                <h2 class="wow zoomIn" data-wow-delay="0.1s">Portfolio</h2>
                <div class="desktop-view">
                  <ul
                    class="row portfolio-filter menu-list wow zoomIn"
                    data-wow-delay="0.1s"
                  >
                    <li
                      v-for="opt in filterOptions"
                      :key="opt"
                      :class="{ active: this.current == opt.value }"
                    >
                      <a href="#" @click="filterItems(opt.value, opt.text)">{{
                        opt.text
                      }}</a>
                    </li>
                    <!-- <li :class="{ active: this.current == '' }">
                      <a href="#" @click="filterItems('')">All Projects</a>
                    </li>

                    <li :class="{ active: this.current == 'shopify' }">
                      <a href="#" @click="filterItems('shopify')">Shopify</a>
                    </li>

                    <li :class="{ active: this.current == 'ruby' }">
                      <a href="#" @click="filterItems('ruby')">Ruby on Rails</a>
                    </li>

                    <li :class="{ active: this.current == 'react' }">
                      <a href="#" @click="filterItems('react')">React JS</a>
                    </li>

                    <li :class="{ active: this.current == 'vue' }">
                      <a href="#" @click="filterItems('vue')">Vue JS</a>
                    </li>

                    <li :class="{ active: this.current == 'node' }">
                      <a href="#" @click="filterItems('node')">Node JS</a>
                    </li>

                    <li :class="{ active: this.current == 'mobile' }">
                      <a href="#" @click="filterItems('mobile')">Mobile App</a>
                    </li> -->
                  </ul>
                  <hr class="wow zoomIn" data-wow-delay="0.1s" />
                </div>
                <div class="mobile-view">
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ this.currentDropdown }}
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        href="#"
                        v-for="opt in filterOptions"
                        :key="opt"
                        @click="filterItems(opt.value, opt.text)"
                        >{{ opt.text }}</a
                      >
                      <!-- <a class="dropdown-item" href="#" @click="filterItems('')"
                        >All Projects</a
                      >

                      <a
                        class="dropdown-item"
                        href="#"
                        @click="filterItems('shopify')"
                        >Shopify</a
                      >

                      <a
                        class="dropdown-item"
                        href="#"
                        @click="filterItems('ruby')"
                        >Ruby on Rails</a
                      >

                      <a
                        class="dropdown-item"
                        href="#"
                        @click="filterItems('react')"
                        >React JS</a
                      >

                      <a
                        class="dropdown-item"
                        href="#"
                        @click="filterItems('vue')"
                        >Vue JS</a
                      >

                      <a
                        class="dropdown-item"
                        href="#"
                        @click="filterItems('node')"
                        >Node JS</a
                      >

                      <a
                        class="dropdown-item"
                        href="#"
                        @click="filterItems('mobile')"
                        >Mobile App</a
                      > -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="container">
                <div
                  v-for="item in filteredItems"
                  :key="item"
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      :src="item.desktopImage"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        :src="item.mobileImage"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>{{ item.title }}</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">{{ item.title }}®</span>
                        {{ item.desc }}<br /><br />
                        <span v-for="(point, ind) in item.points" :key="ind"
                          >{{ ind + 1 }}. {{ point }}<br
                        /></span>
                      </p>
                      <div class="row">
                        <div
                          class="mini-logo"
                          v-for="(logo, ind) in item.techs"
                          :key="ind"
                        >
                          <img class="img-holder" :src="getLogoImage(logo)" />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a :href="item.link" target="_blank"> View Project</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--                 
                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/FlavorwikiMockup2_750x4cf7.png?v=1654192480"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/FlavorwikiMockup2_600x4cf7.png?v=1654192480"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>FlavorWiki</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">FlavorWiki®</span> Share your tasting
                        experience and earn great rewards:<br /><br />
                        1. Provides insights to famous brands in the food
                        industry.<br />
                        2. Become a taster to earn rewards.<br />
                        3. Participate in surveys and share your experience with
                        friends.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/PHP_480x48017ce.png?v=1652450468"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720_160x160.webp?v=1652450258"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://flavorwiki.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/ZiplyMockup_750x6df2.png?v=1654192516"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/ZiplyMockup_600x6df2.png?v=1654192516"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Ziply</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Ziply®</span> Ziply operates as an
                        on-demand shipping service, allowing customers to order
                        couriers to deliver anything in an efficient manner
                        without having to leave their homes:<br /><br />
                        1. Same day, door-to-door delivery.<br />
                        2. Couriers are available 24/7, 365 days a year.<br />
                        3. Request from your phone, laptop or desktop.<br />
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Iosec90.png?v=1652450426"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://goziply.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/PlexxisMockup_750x35d4.png?v=1654192501"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/PlexxisMockup_600x35d4.png?v=1654192501"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Plexxis Software</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Plexxis Software®</span> Coupling
                        cloud construction management software, on-premise and
                        hosted solutions, Plexxis unites operations, estimating,
                        accounting and field apps on a single technology
                        stack:<br /><br />
                        1. For subcontractors who seek elite team cohesion and
                        performance.<br />
                        2. Single, unified platform created in-house.<br />
                        3. End-to-end optimization of the construction sector.
                      </p>
                      <div class="row">
                        <div class="mini-logo" style="text-align: start">
                          <img
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720_160x160.webp?v=1652450258"
                            class="img-holder"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://plexxis.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/NYAdventureClubMockup_750x1dd1.png?v=1654192497"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/NYAdventureClubMockup_600x1dd1.png?v=1654192497"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>New York Adventure Club</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">New York Adventure Club®</span>
                        Enabling the discovery of hidden gems in NYC and beyond
                        through physical exploration:<br /><br />
                        1. Explore the unknown stories of everyday places.<br />
                        2. Learn from experts who define urban culture.<br />
                        3. Join us physically or virtually.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720.webp?v=1652450258"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413"
                            style="float: none"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a
                          href="https://www.nyadventureclub.com/"
                          target="_blank"
                        >
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/SonoviaMockup_750xb5a7.png?v=1654192508"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/SonoviaMockup_600xb5a7.png?v=1654192508"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Sonovia</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Sonovia®</span> Fabric-based smart
                        products, designed for smart living:<br /><br />
                        1. Stay-clean fabric.<br />
                        2. Anti-odor products.<br />
                        3. Environment-friendly, water-based chemical
                        formulations.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://sonoviashop.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CommunyMockup_750xe09a.png?v=1654192472"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CommunyMockup_600xe09a.png?v=1654192472"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Communynow</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Communynow®</span> Visit your
                        favorite schools from the comfort of your home. Interact
                        and engage with the guide or just sit back, relax and
                        take notes:<br /><br />
                        1. Interact and engage with the guide or just sit back,
                        relax and take notes.<br />
                        2. Connect with current students from university across
                        the country.<br />
                        3. Ask questions in real time with an in-stream chat
                        function.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWSa455.png?v=1652450413"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/React_Nativebb4c.png?v=1652450484"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://communynow.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/TeehatchMockup_750x4046.png?v=1654192509"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/TeehatchMockup_600x4046.png?v=1654192509"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Teehatch</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Teehatch®</span> The drop shipper
                        delivering print-on-demand t-shirts and hats to your
                        customers in Canada:<br /><br />
                        1. The first t-shirt drop shipping company in the
                        country.<br />
                        2. End-to-end optimization of the e-commerce process.<br />
                        3. Facilitates DTG printing and embroidery.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720.webp?v=1652450258"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Angular_Jsb1dc.png?v=1652450345"
                            class="img-holder"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://www.teehatch.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/RLDDesignMockup_750x6e57.png?v=1654192504"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/RLDDesignMockup_600x6e57.png?v=1654192504"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>RLD Design</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">RLD Design®</span> World-class canopy
                        manufacturer and supplier of stainless-steel
                        canopies:<br /><br />
                        1. An international company supplying durable canopies
                        all over the globe.<br />
                        2. Our Generation 3 canopy has a modular design.<br />
                        3. The unique design enables you to add and remove
                        accessories as you please.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://rlddesign.co.za/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/PennyPervilleProject_750x0ef3.png?v=1654192499"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/PennyPervilleProject_600x0ef3.png?v=1654192499"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Penny Preville</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Penny Preville®</span> Handcrafted
                        fine jewelry celebrating style and femininity, designed
                        to last a lifetime:<br /><br />
                        1. Timeless designs.<br />
                        2. Blending fine craftsmanship with modern appeal for
                        everyday lifestyle.<br />
                        3. Heirloom quality engagement rings and wedding bands.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a
                          href="https://www.pennypreville.com/"
                          target="_blank"
                        >
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/ZatorieMockup_750xeb21.png?v=1654192515"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/ZatorieMockup_600xeb21.png?v=1654192515"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Zatorie</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Zatorie®</span> The most trusted
                        provider of acrylic invitations for more than a
                        decade:<br /><br />
                        1. USA made; 100% satisfaction guaranteed.<br />
                        2. Free sample kit preview before you order.<br />
                        3. Buy now, pay later financing options.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://zatorie.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/WoolloomoolooShoeMockup_750x3f92.png?v=1654192513"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/WoolloomoolooShoeMockup_600x3f92.png?v=1654192513"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Woolloomooloo Shoe</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Woolloomooloo Shoe®</span> The
                        world’s most comfortable and versatile shoes, made with
                        the incredible Australian Merino wool:<br /><br />
                        1. Made with the incredible Australian Merino wool.<br />
                        2. Shoes for those who march to their own beat.<br />
                        3. Digitally knitted for supreme softness, breathability
                        and durability.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                            class="img-holder"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a
                          href="https://www.woolloomoolooshoe.com/"
                          target="_blank"
                        >
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/Mott_BayardMockup_750xb922.png?v=1654192494"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/Mott_BayardMockup_600xb922.png?v=1654192494"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Mott & Bayard Eyewear</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Mott &amp; Bayard Eyewear®</span>
                        Revolutionizing the way you shop for artisan curated
                        glasses:<br /><br />
                        1. Glasses that are made with your face in mind.<br />
                        2. Glasses that don’t life when you smile or slide of
                        your nose.<br />
                        3. No more squinting or pinching.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a
                          href="https://www.mottandbayard.com/"
                          target="_blank"
                        >
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/LoopMockup_750x0cb4.png?v=1654192489"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/LoopMockup_600x0cb4.png?v=1654192489"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Loop</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Loop®</span> Luxury quality rings
                        that fit her style and your budget:<br /><br />
                        1. Fair prices.<br />
                        2. Responsibly sourced, hand selected diamonds.<br />
                        3. Complimentary maintenance and resizing.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://loopjewelry.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/littlewordproject_750x0f71.png?v=1654192487"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/littlewordproject_600x0f71.png?v=1654192487"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Little Words Project</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Little Words Project®</span>
                        Bracelets made to be worn and passed on – paying
                        kindness forward one bracelet at a time:<br /><br />
                        1. Wear it. Share it. Track it.<br />
                        2. Aims to encourage and inspire kindness.<br />
                        3. Earn and redeem points.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/React_Nativebb4c.png?v=1652450484"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a
                          href="https://littlewordsproject.com/"
                          target="_blank"
                        >
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/LinesheetMockup_750xad97.png?v=1654192485"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/LinesheetMockup_600xad97.png?v=1654192485"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Linesheets</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Linesheets®</span> Streamlined,
                        template-based package that allows you to organize,
                        customize and share your product collections at
                        lightning speed:<br /><br />
                        1. Linesheet automation.<br />
                        2. Facilitates the targeting of small boutiques and
                        large retailers.<br />
                        3. A great alternative to hiring professional designers.
                      </p>
                      <div class="row">
                        <div class="mini-logo" style="text-align: start">
                          <img
                            class="img-holder"
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720_160x160.webp?v=1652450258"
                            style="float: none"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/AWSa455.png?v=1652450413"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://www.linesheets.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/KudobuzzMockup_750xc014.png?v=1654192484"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/KudobuzzMockup_600xc014.png?v=1654192484"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Kudobuzz</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Kudobuzz®</span> Kudobuzz helps more
                        than 20,000 businesses increase their organic traffic
                        &amp; sales with ratings, photo reviews and social
                        proof:<br /><br />
                        1. Collect verified social reviews.<br />
                        2. Get authentic reviews and ratings.<br />
                        3. All your reviews and visual marketing in one place.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://kudobuzz.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/HangframingMockup_750xaf51.png?v=1654192482"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/HangframingMockup_600xaf51.png?v=1654192482"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Hang Framing</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Hang Framing®</span> Whether it’s a
                        smartphone photo or your favorite piece of art, Hang
                        Framing can custom frame it all:<br /><br />
                        1. Frame your art, photographs, prints or
                        certificates.<br />
                        2. Design a frame for your digital photos and customize
                        everything.<br />
                        3. Upload your digital photos and select a readymade
                        frame.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Vue_Jse671.png?v=1652450290"
                            class="img-holder"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                            class="img-holder"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://www.hangframing.com/ " target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CurbstandMockup_750xd389.png?v=1654192476"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CurbstandMockup_600xd389.png?v=1654192476"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Curbstand</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Curbstand®</span> Curbstand is a
                        full-service parking management and valet service based
                        in Los Angeles:<br /><br />
                        1. The cashless way to valet.<br />
                        2. Reliable partners for hotels, restaurants, airports
                        and commercial buildings.<br />
                        3. Exclusive access to unlimited parking.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720.webp?v=1652450258"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Iosec90.png?v=1652450426"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://curbstand.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CoordinatesCollectionMockup_750x479e.png?v=1654192474"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CoordinatesCollectionMockup_600x479e.png?v=1654192474"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Coordinates Collection</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Coordinates Collection®</span>
                        Customizable and personalized jewelry engraved with the
                        coordinates of the place that holds your memories:<br /><br />
                        1. Immaculate design.<br />
                        2. Quality materials.<br />
                        3. International shipping.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo_100x10036fe.png?v=1652450523"
                            class="img-holder"
                            style="float: none"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a
                          href="https://www.coordinatescollection.com/"
                          target="_blank"
                        >
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CloverlyMockup_750x4dcb.png?v=1654192470"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/CloverlyMockup_600x4dcb.png?v=1654192470"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Cloverly</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Cloverly ®</span> Platform for carbon
                        reduction solutions. From payments to travel to
                        shipping, Colverly's API helps you build sustainable
                        products for your customers:<br /><br />
                        1. Platform for carbon reduction solutions.<br />
                        2. Neutralize the environmental impact of every
                        transaction.<br />
                        3. Offset your carbon footprint in real-time.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                        <div class="mini-logo">
                          <img
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512"
                            class="img-holder"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://www.cloverly.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/BuckPalmerMockup_750x538b.png?v=1654192468"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/BuckPalmerMockup_600x538b.png?v=1654192468"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Buck Palmer</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Buck Palmer®</span> Handmade, rustic
                        luxe jewelry carefully crafted for the adventurous
                        spirit:<br /><br />
                        1. Made and worn by the maverick.<br />
                        2. Continuously inspired by travelling the globe.<br />
                        3. The creations only use the highest quality metals.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                            style="font-size: 1.4em"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://buckpalmer.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="row portfolio-list wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img desktop-view"
                  >
                    <img
                      class="img-holder"
                      src="cdn.shopify.com/s/files/1/0578/3132/5740/products/AlleyoopMockup_750xf787.png?v=1654192461"
                      alt="project image"
                      defer
                    />
                  </div>
                  <div
                    class="col-lg-6 col-md-12 portfolio-project-img mobile-view"
                  >
                    <a href="#">
                      <img
                        class="img-holder"
                        src="cdn.shopify.com/s/files/1/0578/3132/5740/products/AlleyoopMockup_600xf787.png?v=1654192461"
                        alt="project image"
                        defer
                      />
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-12 project-dec">
                    <h3>Alleyoop</h3>
                    <div class="project-descrption">
                      <p>
                        <span class="bold">Alleyoop®</span> Simplifying your
                        world and makeup bag with multifunctional essentials:<br /><br />
                        1. Personalized products that will declutter your
                        life.<br />
                        2. Save time, money, and space.<br />
                        3. Products that redefine efficiency.
                      </p>
                      <div class="row">
                        <div class="mini-logo">
                          <img
                            class="img-holder"
                            src="cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523"
                          />
                        </div>
                      </div>
                      <div class="project-descrption-link">
                        <a href="https://meetalleyoop.com/" target="_blank">
                          View Project</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
 -->
                <!--         <div class="load-more-btn row desktop-view mobile-view">
          <button class="col-12 load-more"> Load More </button>
        </div> -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>

    <ul hidden>
      <li id="a11y-refresh-page-message">
        Choosing a selection results in a full page refresh.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
  data() {
    return {
      current: "",
      currentDropdown: "All Projects",
      filterOptions: [
        {
          text: "All Projects",
          value: "",
        },
        {
          text: "Shopify",
          value: "shopify",
        },
        {
          text: "Ruby on Rails",
          value: "ruby",
        },
        {
          text: "React JS",
          value: "react",
        },
        {
          text: "Vue JS",
          value: "vue",
        },
        {
          text: "Node JS",
          value: "node",
        },
        {
          text: "Mobile App",
          value: "mobile",
        },
      ],
      filteredItems: [],
      allItems: [
        {
          title: "FlavorWiki",
          desc: "Share your tasting experience and earn great rewards:",
          points: [
            "Provides insights to famous brands in the food industry.",
            "Become a taster to earn rewards.",
            "Participate in surveys and share your experience with friends.",
          ],
          techs: ["react", "php", "node", "ruby", "aws"],
          link: "https://flavorwiki.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/FlavorwikiMockup2_750x4cf7.png?v=1654192480",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/FlavorwikiMockup2_600x4cf7.png?v=1654192480",
        },
        {
          title: "Ziply",
          desc: "Ziply operates as an on-demand shipping service, allowing customers to order couriers to deliver anything in an efficient manner without having to leave their homes:",
          points: [
            "Same day, door-to-door delivery.",
            "Couriers are available 24/7, 365 days a year.",
            "Request from your phone, laptop or desktop.",
          ],
          techs: ["react", "ruby", "aws", "ios"],
          link: "https://goziply.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/ZiplyMockup_750x6df2.png?v=1654192516",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/ZiplyMockup_600x6df2.png?v=1654192516",
        },
        {
          title: "Plexxis Software",
          desc: "Coupling cloud construction management software, on-premise and hosted solutions, Plexxis unites operations, estimating, accounting and field apps on a single technology stack:",
          points: [
            "For subcontractors who seek elite team cohesion and performance.",
            "Single, unified platform created in-house.",
            "End-to-end optimization of the construction sector.",
          ],
          techs: ["node", "react", "aws"],
          link: "https://plexxis.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/PlexxisMockup_750x35d4.png?v=1654192501",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/PlexxisMockup_600x35d4.png?v=1654192501",
        },
        {
          title: "New York Adventure Club",
          desc: "Enabling the discovery of hidden gems in NYC and beyond through physical exploration:",
          points: [
            "Explore the unknown stories of everyday places.",
            "Learn from experts who define urban culture.",
            "Join us physically or virtually.",
          ],
          techs: ["node", "react", "aws"],
          link: "https://www.nyadventureclub.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/NYAdventureClubMockup_750x1dd1.png?v=1654192497",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/NYAdventureClubMockup_600x1dd1.png?v=1654192497",
        },
        {
          title: "Sonovia",
          desc: "Fabric-based smart products, designed for smart living:",
          points: [
            "Stay-clean fabric.",
            "Anti-odor products.",
            "Environment-friendly, water-based chemical formulations.",
          ],
          techs: ["shopify"],
          link: "https://sonoviashop.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/SonoviaMockup_750xb5a7.png?v=1654192508",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/SonoviaMockup_600xb5a7.png?v=1654192508",
        },
        {
          title: "Communynow",
          desc: "Visit your favorite schools from the comfort of your home. Interact and engage with the guide or just sit back, relax and take notes:",
          points: [
            "Interact and engage with the guide or just sit back, relax and take notes.",
            "Connect with current students from university across the country.",
            "Ask questions in real time with an in-stream chat function.",
          ],
          techs: ["react", "ruby", "aws", "react-native"],
          link: "https://communynow.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CommunyMockup_750xe09a.png?v=1654192472",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CommunyMockup_600xe09a.png?v=1654192472",
        },
        {
          title: "Teehatch",
          desc: "The drop shipper delivering print-on-demand t-shirts and hats to your customers in Canada:",
          points: [
            "The first t-shirt drop shipping company in the country.",
            "End-to-end optimization of the e-commerce process.",
            "Facilitates DTG printing and embroidery.",
          ],
          techs: ["node", "shopify", "angular"],
          link: "https://www.teehatch.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/TeehatchMockup_750x4046.png?v=1654192509",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/TeehatchMockup_600x4046.png?v=1654192509",
        },
        {
          title: "RLD Design",
          desc: "World-class canopy manufacturer and supplier of stainless-steel canopies:",
          points: [
            "An international company supplying durable canopies all over the globe.",
            "Our Generation 3 canopy has a modular design.",
            "The unique design enables you to add and remove accessories as you please.",
          ],
          techs: ["shopify"],
          link: "https://rlddesign.co.za/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/RLDDesignMockup_750x6e57.png?v=1654192504",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/RLDDesignMockup_600x6e57.png?v=1654192504",
        },
        {
          title: "Penny Preville",
          desc: "Handcrafted fine jewelry celebrating style and femininity, designed to last a lifetime:",
          points: [
            "Timeless designs.",
            "Blending fine craftsmanship with modern appeal for everyday lifestyle.",
            "Heirloom quality engagement rings and wedding bands.",
          ],
          techs: ["shopify"],
          link: "https://www.pennypreville.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/PennyPervilleProject_750x0ef3.png?v=1654192499",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/PennyPervilleProject_600x0ef3.png?v=1654192499",
        },
        {
          title: "Zatorie",
          desc: "The most trusted provider of acrylic invitations for more than a decade:",
          points: [
            "USA made; 100% satisfaction guaranteed.",
            "Free sample kit preview before you order.",
            "Buy now, pay later financing options.",
          ],
          techs: ["shopify"],
          link: "https://zatorie.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/ZatorieMockup_750xeb21.png?v=1654192515",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/ZatorieMockup_600xeb21.png?v=1654192515",
        },
        {
          title: "Woolloomooloo Shoe",
          desc: "The world’s most comfortable and versatile shoes, made with the incredible Australian Merino wool:",
          points: [
            "Made with the incredible Australian Merino wool.",
            "Shoes for those who march to their own beat.",
            "Digitally knitted for supreme softness, breathability and durability.",
          ],
          techs: ["shopify"],
          link: "https://www.woolloomoolooshoe.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/WoolloomoolooShoeMockup_750x3f92.png?v=1654192513",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/WoolloomoolooShoeMockup_600x3f92.png?v=1654192513",
        },
        {
          title: "Mott & Bayard Eyewear",
          desc: "Revolutionizing the way you shop for artisan curated glasses:",
          points: [
            "Glasses that are made with your face in mind.",
            "Glasses that don’t life when you smile or slide of your nose.",
            "No more squinting or pinching.",
          ],
          techs: ["shopify", "ruby"],
          link: "https://www.mottandbayard.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/Mott_BayardMockup_750xb922.png?v=1654192494",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/Mott_BayardMockup_600xb922.png?v=1654192494",
        },
        {
          title: "Loop",
          desc: "Luxury quality rings that fit her style and your budget:",
          points: [
            "Fair prices.",
            "Responsibly sourced, hand selected diamonds.",
            "Complimentary maintenance and resizing.",
          ],
          techs: ["shopify"],
          link: "https://loopjewelry.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/LoopMockup_750x0cb4.png?v=1654192489",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/LoopMockup_600x0cb4.png?v=1654192489",
        },
        {
          title: "Little Words Project",
          desc: "Bracelets made to be worn and passed on – paying kindness forward one bracelet at a time:",
          points: [
            "Wear it. Share it. Track it.",
            "Aims to encourage and inspire kindness.",
            "Earn and redeem points.",
          ],
          techs: ["shopify", "ruby", "react", "react-native"],
          link: "https://littlewordsproject.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/littlewordproject_750x0f71.png?v=1654192487",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/littlewordproject_600x0f71.png?v=1654192487",
        },
        {
          title: "Linesheets",
          desc: "Streamlined, template-based package that allows you to organize, customize and share your product collections at lightning speed:",
          points: [
            "Linesheet automation.",
            "Facilitates the targeting of small boutiques and large retailers.",
            "A great alternative to hiring professional designers.",
          ],
          techs: ["node", "ruby", "react", "aws"],
          link: "https://www.linesheets.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/LinesheetMockup_750xad97.png?v=1654192485",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/LinesheetMockup_600xad97.png?v=1654192485",
        },
        {
          title: "Kudobuzz",
          desc: "Kudobuzz helps more than 20,000 businesses increase their organic traffic & sales with ratings, photo reviews and social proof:",
          points: [
            "Collect verified social reviews.",
            "Get authentic reviews and ratings.",
            "All your reviews and visual marketing in one place.",
          ],
          techs: ["ruby", "react", "shopify"],
          link: "https://kudobuzz.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/KudobuzzMockup_750xc014.png?v=1654192484",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/KudobuzzMockup_600xc014.png?v=1654192484",
        },
        {
          title: "Hang Framing",
          desc: "Whether it’s a smartphone photo or your favorite piece of art, Hang Framing can custom frame it all:",
          points: [
            "Frame your art, photographs, prints or certificates.",
            "Design a frame for your digital photos and customize everything.",
            "Upload your digital photos and select a readymade frame.",
          ],
          techs: ["vue", "ruby"],
          link: "https://www.hangframing.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/HangframingMockup_750xaf51.png?v=1654192482",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/HangframingMockup_600xaf51.png?v=1654192482",
        },
        {
          title: "Curbstand",
          desc: "Curbstand is a full-service parking management and valet service based in Los Angeles:",
          points: [
            "The cashless way to valet.",
            "Reliable partners for hotels, restaurants, airports and commercial buildings.",
            "Exclusive access to unlimited parking.",
          ],
          techs: ["react", "node", "ios"],
          link: "https://curbstand.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CurbstandMockup_750xd389.png?v=1654192476",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CurbstandMockup_600xd389.png?v=1654192476",
        },
        {
          title: "Coordinates Collection",
          desc: "Customizable and personalized jewelry engraved with the coordinates of the place that holds your memories:",
          points: [
            "Immaculate design.",
            "Quality materials.",
            "International shipping.",
          ],
          techs: ["shopify"],
          link: "https://www.coordinatescollection.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CoordinatesCollectionMockup_750x479e.png?v=1654192474",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CoordinatesCollectionMockup_600x479e.png?v=1654192474",
        },
        {
          title: "Cloverly",
          desc: "Platform for carbon reduction solutions. From payments to travel to shipping, Colverly's API helps you build sustainable products for your customers:",
          points: [
            "Platform for carbon reduction solutions.",
            "Neutralize the environmental impact of every transaction.",
            "Offset your carbon footprint in real-time.",
          ],
          techs: ["shopify", "ruby"],
          link: "https://www.cloverly.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CloverlyMockup_750x4dcb.png?v=1654192470",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/CloverlyMockup_600x4dcb.png?v=1654192470",
        },
        {
          title: "Buck Palmer",
          desc: "Handmade, rustic luxe jewelry carefully crafted for the adventurous spirit:",
          points: [
            "Made and worn by the maverick.",
            "Continuously inspired by travelling the globe.",
            "The creations only use the highest quality metals.",
          ],
          techs: ["shopify"],
          link: "https://buckpalmer.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/BuckPalmerMockup_750x538b.png?v=1654192468",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/BuckPalmerMockup_600x538b.png?v=1654192468",
        },
        {
          title: "Alleyoop",
          desc: "Simplifying your world and makeup bag with multifunctional essentials:",
          points: [
            "Personalized products that will declutter your life.",
            "Save time, money, and space.",
            "Products that redefine efficiency",
          ],
          techs: ["shopify"],
          link: "https://meetalleyoop.com/",
          desktopImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/AlleyoopMockup_750xf787.png?v=1654192461",
          mobileImage:
            "cdn.shopify.com/s/files/1/0578/3132/5740/products/AlleyoopMockup_600xf787.png?v=1654192461",
        },
      ],
      techInfo: [
        {
          name: "shopify",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/Shopify-Logo36fe.png?v=1652450523",
        },
        {
          name: "ruby",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/Ruby_On_Rails9d40.png?v=1652450512",
        },
        {
          name: "react",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/Reactf3d6.png?v=1652450500",
        },
        {
          name: "node",
          image:
            "https://cdn.shopify.com/s/files/1/0641/0924/8766/files/node-js-736399_960_720_160x160.webp?v=1652450258",
        },
        {
          name: "ios",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/Iosec90.png?v=1652450426",
        },
        {
          name: "aws",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/AWS_160x160a455.png?v=1652450413",
        },
        {
          name: "react-native",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/React_Nativebb4c.png?v=1652450484",
        },
        {
          name: "php",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/PHP_480x48017ce.png?v=1652450468",
        },
        {
          name: "angular",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/Angular_Jsb1dc.png?v=1652450345",
        },
        {
          name: "vue",
          image:
            "cdn.shopify.com/s/files/1/0641/0924/8766/files/Vue_Jse671.png?v=1652450290",
        },
      ],
    };
  },
  created() {
    this.filterItems("", "All Projects");
  },
  methods: {
    getLogoImage(tech) {
      return this.techInfo.find((el) => el.name == tech).image;
    },
    filterItems(value, text) {
      this.current = value;
      this.currentDropdown = text;
      if (value == "") this.filteredItems = this.allItems;
      else if (value == "mobile") {
        this.filteredItems = this.allItems.filter(
          (el) => el.techs.includes("react-native") || el.techs.includes("ios")
        );
      } else {
        this.filteredItems = this.allItems.filter((el) =>
          el.techs.includes(value)
        );
      }
    },
  },
};
</script>

<style scoped></style>
